import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import css from './SectionCommunity.module.css';

import illustration from './image.png';

const SectionCommunity = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.content}>
        <div className={css.title}>
          <FormattedMessage id="SectionCommunity.title" />
        </div>

        <h2 className={css.text1}>
          <FormattedMessage id="SectionCommunity.text1" />
        </h2>
        <p className={css.text2}>
          <FormattedMessage id="SectionCommunity.text2" />
        </p>
      </div>

      <div className={css.illustration}>
        <img src={illustration} />
      </div>
    </div>
  );
};

SectionCommunity.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionCommunity.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCommunity;
