import React, { Component } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../../util/contextHelpers';

import { NamedLink } from '../../../components';

import css from './SectionFilteredSearches.module.css';

// Update images by saving images to src/LandingPage/SeactionFilteredSearches/images directory.
import imageForFilter1 from './images/groenten.png';
import imageForFilter1_2x from './images/groenten@2x.png';
import imageForFilter2 from './images/fruit.png';
import imageForFilter2_2x from './images/fruit@2x.png';
import imageForFilter3 from './images/planten.png';
import imageForFilter3_2x from './images/planten@2x.png';
import imageForFilter4 from './images/bloemen.png';
import imageForFilter4_2x from './images/bloemen@2x.png';
import imageForFilter5 from './images/zaden.png';
import imageForFilter5_2x from './images/zaden@2x.png';
import imageForFilter6 from './images/vers.png';
import imageForFilter6_2x from './images/vers@2x.png';
import imageForFilter7 from './images/beno.png';
import imageForFilter7_2x from './images/beno@2x.png';
import imageForFilter8 from './images/diensten.png';
import imageForFilter8_2x from './images/diensten@2x.png';

// Thumbnail image for the search "card"
class ThumbnailImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
// Load the image only if it's close to viewport (user has scrolled the page enough).
const LazyImage = lazyLoadWithDimensions(ThumbnailImage);

// Create a "card" that contains a link to filtered search on SearchPage.
const FilterLink = props => {
  const { name, image, link } = props;
  const url = typeof window !== 'undefined' ? new window.URL(link) : new global.URL(link);
  const searchQuery = url.search;
  const nameText = <span className={css.searchName}>{name}</span>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.searchLink}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.searchImage} />
        </div>
        <div className={css.imageOverlay}>
          <div className={css.linkText}>
            <FormattedMessage
              id="SectionFilteredSearches.filteredSearch"
              values={{ filter: nameText }}
            />
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

// Component that shows full-width section on LandingPage.
// Inside it shows 3 "cards" that link to SearchPage with specific filters applied.
const SectionFilteredSearches = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // FilterLink props:
  // - "name" is a string that defines what kind of search the link is going to make
  // - "image" is imported from images directory and you can update it by changing the file
  // - "link" should be copy-pasted URL from search page.
  //    The domain doesn't matter, but search query does. (I.e. "?pub_brand=nike")
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionFilteredSearches.title" />
      </div>
      <div className={css.filteredSearches}>
        <FilterLink
          name="Groenten"
          image={imageForFilter1}
          image2x={imageForFilter1_2x}
          link="http://localhost:3000/s?pub_category=groenten"
        />
        <FilterLink
          name="Fruit"
          image={imageForFilter2}
          image2x={imageForFilter2_2x}
          link="http://localhost:3000/s?pub_category=fruit"
        />
        <FilterLink
          name="Planten"
          image={imageForFilter3}
          image2x={imageForFilter3_2x}
          link="http://localhost:3000/s?pub_category=planten"
        />
        <FilterLink
          name="Bloemen"
          image={imageForFilter4}
          image2x={imageForFilter4_2x}
          link="http://localhost:3000/s?pub_category=bloemen"
        />
        <FilterLink
          name="Zaden"
          image={imageForFilter5}
          image2x={imageForFilter5_2x}
          link="http://localhost:3000/s?pub_category=zaden"
        />
        <FilterLink
          name="Vers- en groentepakketten"
          image={imageForFilter6}
          image2x={imageForFilter6_2x}
          link="http://localhost:3000/s?pub_category=groenten-en-fruitpakketten"
        />
        <FilterLink
          name="Benodigdheden"
          image={imageForFilter7}
          image2x={imageForFilter7_2x}
          link="http://localhost:3000/s?pub_category=benodigdheden"
        />
        <FilterLink
          name="Diensten"
          image={imageForFilter8}
          image2x={imageForFilter8_2x}
          link="http://localhost:3000/s?pub_category=diensten"
        />
      </div>
    </div>
  );
};

SectionFilteredSearches.defaultProps = { rootClassName: null, className: null };

SectionFilteredSearches.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionFilteredSearches;
