import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';
import { ListingCard } from '../../../components';
import css from './SectionNewListings.module.css';

const SectionNewListings = props => {
  const { rootClassName, className, listings, intl } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="SectionNewListings.title" />
      </h2>
      <div className={css.listings}>
        {listings.map(l => (
          <ListingCard key={l.id.uuid} intl={intl} listing={l} className={css.listing} />
        ))}
      </div>
    </div>
  );
};

export default SectionNewListings;
