import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink } from '../../../components';

import heroImage from './hero-img-new.png';
import heroImage2x from './hero-img-new@2x.png';
import css from './SectionHero.module.css';

import LogoImage from './logo.svg';

const SectionHero = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, isAuthenticated } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;

  const classes = classNames(rootClassName || css.root, className);
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h2
          className={classNames(css.heroIntroTitle, { [css.heroMobileFullTitleFEDelay]: mounted })}
        >
          <FormattedMessage
            id="SectionHero.intro"
            values={{
              icon: (
                <img
                  className={css.imageLogo}
                  src={LogoImage}
                  height={isMobile ? '20px' : '44px'}
                />
              ),
            }}
          />
        </h2>
        {isMobile ? (
          <h1
            className={classNames(css.heroMobileFullTitle, {
              [css.heroMobileFullTitleFEDelay]: mounted,
            })}
          >
            <FormattedMessage id="SectionHero.titleMobileFull" />
          </h1>
        ) : (
          <>
            <h1
              className={classNames(css.heroDesktopOneTitle, {
                [css.heroDesktopOneTitleFEDelay]: mounted,
              })}
            >
              <FormattedMessage id="SectionHero.titleDesktopOne" />
            </h1>
            <h1
              className={classNames(css.heroDesktopTwoTitle, {
                [css.heroDesktopTwoTitleFEDelay]: mounted,
              })}
            >
              <FormattedMessage id="SectionHero.titleDesktopTwo" />
            </h1>
          </>
        )}
        <div className={css.heroImageMobile}>
          <picture>
            <source srcSet={`${heroImage2x} 1.5x`} />{' '}
            <img src={heroImage} className={css.heroImage} />
          </picture>
        </div>
        <h3 className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h3>
        <div className={css.heroButtonRow}>
          <NamedLink
            name="SearchPage"
            className={classNames(css.heroButtonSecondary, { [css.heroButtonFEDelay]: mounted })}
          >
            <FormattedMessage id="SectionHero.howItWorksButton" />
          </NamedLink>
          {authenticatedOnClientSide ? (
            <NamedLink
              name="NewListingPage"
              className={classNames(css.heroButton, { [css.heroButtonFEDelay]: mounted })}
            >
              <FormattedMessage id="SectionHero.addListingButton" />
            </NamedLink>
          ) : (
            <NamedLink
              name="SignupPage"
              className={classNames(css.heroButton, { [css.heroButtonFEDelay]: mounted })}
            >
              <FormattedMessage id="SectionHero.signInButton" />
            </NamedLink>
          )}
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  isAuthenticated: bool.isRequired,
};

export default SectionHero;
