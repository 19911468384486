import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';

import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import facebookImage from '../../assets/cropsieFacebook-1200x630.jpg';
import twitterImage from '../../assets/cropsieTwitter-600x314.jpg';

import SectionHero from './SectionHero/SectionHero';
import SectionIntro from './SectionIntro/SectionIntro';
import SectionNewListings from './SectionNewListings/SectionNewListings';
import SectionHowItWorks from './SectionHowItWorks/SectionHowItWorks';
import SectionFilteredSearches from './SectionFilteredSearches/SectionFilteredSearches';
import css from './LandingPage.module.css';
import SectionCommunity from './SectionCommunity/SectionCommunity';

export const LandingPageComponent = props => {
  const { history, intl, listings, location, scrollingDisabled, isAuthenticated } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[
        { url: `${config.canonicalRootURL}${facebookImage}`, width: 1200, height: 630 },
      ]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero
              rootClassName={css.heroRoot}
              className={css.hero}
              history={history}
              location={location}
              isAuthenticated={isAuthenticated}
            />
          </div>
          <div className={css.newListingsRoot}>
            <div className={css.sectionContent}>
              <SectionNewListings intl={intl} listings={listings} />
            </div>
          </div>
          <div className={css.categoriesRoot}>
            <div className={css.sectionContent}>
              <SectionFilteredSearches />
            </div>
          </div>
          <div className={css.howItWorksRoot}>
            <div className={css.sectionContent}>
              <SectionHowItWorks />
            </div>
          </div>
          <div className={css.communityRoot}>
            <div className={css.sectionContent}>
              <SectionCommunity />
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  listings: [],
};

const { array, bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  listings: array,
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  isAuthenticated: bool.isRequired,
};

const mapStateToProps = state => {
  const { currentPageResultIds } = state.SearchPage;
  const { isAuthenticated } = state.Auth;
  const pageListings = getListingsById(state, currentPageResultIds);
  return {
    listings: pageListings,
    scrollingDisabled: isScrollingDisabled(state),
    isAuthenticated,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
